<template>
<div class="tgju-widget light post" style="box-shadow: none">
    <div class="post-row">
        <PostItem :post="post" v-if="post" ref="postReshare-PostItem"></PostItem>
    </div>
    <!-- فرم ارسال توییت -->
    <PostSend v-if="user" :newPost="newPost" :flow="this.data.where" ref="postReshare-postSend"></PostSend>
    <slot v-else>
        ابتدا وارد شوید
    </slot>
</div>
</template>

<style lang="scss">
.user-block-comment-items ul li.like {
    position: relative;
    top: 3px;
    float: none;
}

.user-block-comment-item-content .emoji {
    width: 17px !important;
    margin-right: 3px !important;
}

.user-block-comment-item-content .post-img {
    max-width: 100%;
    margin-top: 20px;
}
</style>

<script>
// این کامپوننت برای باز نشر یک پست استفاده میشود
// وضعیت فعلی : غیرفعال می باشد
import PostSend from '@/components/Posts/PostSend.vue'
import PostItem from '@/components/Posts/PostItem.vue'

export default {
    name: 'PostReshareModal',
    props: ['data'],
    components: {
        PostSend,
        PostItem
    },
    data: function () {
        return {
            newPost: {
                content: '',
                type: null,
                group_id: null,
                attachments: [],
                parent_id: null,
                parent: null,
            },
            post: null,
            user: this.$helpers.userSync(),
        }
    },
    computed: {
        posts: {
            get() {
                return this.data.posts;
            },
            set(value) {
                this.data.posts = value;
            }
        },
    },
    watch: {
        data: {
            handler: function (newValue, oldValue) {
                if (Object.keys(newValue).length) {
                    this.post = this.data.post;

                    this.newPost.type = 'post';
                    this.newPost.parent_id = this.post.id;
                    this.newPost.parent = this.post;
                    this.newPost.group_id = this.data.group_id;

                    if (typeof oldValue == 'undefined' || !Object.keys(oldValue).length || this.data.post.id != oldValue.post.id) {
                        this.loadData();
                    }
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        loadData() {},
        setCaretToEnd() {
            let postSendComponent = this.$refs['postReshare-postSend'];
            postSendComponent.setCaretToEnd(postSendComponent.$refs['textarea-post']);
        },
        scrollTo(container) {
            let postSendComponent = this.$refs['postReshare-postSend'];
            postSendComponent.scrollTo(container);
        },
        closeModal() {
            this.$parent.closeModal();
        },
    },
}
</script>
