<template>
<div class="tgju-widget light post mb-3 post-input-box-widget">
    <div class="post-input-box" ref="post-input-box">
        <form @submit.prevent="">
            <Mentionable :keys="['@']" :items="mentionItems" offset="6" insert-space @open="mentionOnOpen">
                <Editable v-model="$parent.newPost.content" class="autosize dashboard-post-textarea textarea-post twemojiTextarea" ref="textarea-post" placeholder="متن خود را بنویسید ..."></Editable>
                <template #no-result>
                    <div class="dim">
                        No result
                    </div>
                </template>
                <template #item-@="{ item }">
                    <div class="item">
                        {{ item.value }}
                        <span class="dim">
                            ({{ item.username }})
                        </span>
                    </div>
                </template>
            </Mentionable>
            <div class="dashboard-post-preview-image" v-if="$parent.newPost.attachments.length">
                <div v-for="attachment in $parent.newPost.attachments" :key="attachment.id" class="dashboard-post-preview-data">
                    <div class="preview_image-delete" @click="removeAttachment(attachment.id)"><i class="uil uil-times-circle" aria-hidden="true"></i></div>
                    <img class="preview-image" :src="$helpers.api_url() + attachment.name" alt="" />
                </div>
            </div>
            <div class="dashboard-post-btn">
                <div class="tgju-btn tgju-btn-primary" @click="sendPost()">
                    <i class="uil uil-comment-plus" aria-hidden="true"></i>
                    <template v-if="type == 'post'">{{ $parent.newPost.id ? 'ویرایش توییت' : 'ارسال توییت' }}</template>
                    <template v-else>{{ $parent.newPost.id ? 'ویرایش نظر' : 'ارسال نظر' }}</template>
                </div>
                <div class="m-right tgju-btn tgju-btn-danger" @click="resetNewPost()" v-if="$parent.newPost.id || ($parent.newPost.parent_id && $parent.newPost.parent.type != 'post')">
                    <i class="uil uil-times" aria-hidden="true"></i>
                    انصراف
                </div>
                <div class="dashboard-post-btn-left">
                    <label>
                        <!-- <twemoji-picker :emojiData="emojiDataAll" :emojiGroups="emojiGroups" :skinsSelection="false" :searchEmojisFeat="true" searchEmojiPlaceholder="جستجو کنید ..." searchEmojiNotFound="ایموجی یافت نشد" isLoadingLabel="درحال بارگزاری ..."></twemoji-picker> -->
                    </label>
                    <label for="btn-file-pic" class='dashboard-post-btn-file'>
                        <input type="file" id="btn-file-pic" class="btn-file-pic" label="add" @change="uploadAttachment($event.target.files)" />
                        <i class="uil uil-image-plus" aria-hidden="true"></i>
                    </label>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
// import {
//     TwemojiPicker,
//     EmojiService
// } from '@kevinfaguiar/vue-twemoji-picker';
// import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
// import EmojiDataAnimalsNature from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-group-animals-nature.json';
// import EmojiDataFoodDrink from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-group-food-drink.json';
// import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';

import Editable from '@/components/Editable.vue'
import { Mentionable } from 'vue-mention';

export default {
    name: 'PostSend',
    components: {
        // 'twemoji-picker': TwemojiPicker,
        Editable,
        Mentionable
    },
    props: ['newPost', 'flow'],
    data: function () {
        return {
            mentionItems: [],
            users: [{
                    value: 'm.foroughi',
                    username: 'm.foroughi',
                },
                {
                    value: 'a.farmani',
                    username: 'a.farmani',
                }
            ]
        }
    },
    computed: {
        // emojiDataAll() {
        //     return EmojiAllData;
        // },
        // emojiGroups() {
        //     return EmojiGroups;
        // },
        _newPost: {
            get() {
                return this.$parent.newPost;
            },
            set(value) {
                this.$parent.newPost = value;
            }
        },
        type() {
            return this.newPost.type;
        },
        outSideFlow() {
            return this.flow;
        }
    },
    watch: {
        newPost: {
            handler: function (newValue, oldValue) {
                if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                    //
                }
            },
            immediate: true
        }
    },
    mounted() {
        let self = this;
        let post_input_box = $(this.$refs['post-input-box']);
        $(document).on('click', '#emoji-container > #emoji-popup .emoji-popover-inner > div > .emoji-list > span > img', function () {
            if (post_input_box.find('#popper-container').is(":visible")) {
                self.$parent.newPost.content = self.$parent.newPost.content + self.parseEmoji($(this).attr('alt'));
            }
        });
    },
    methods: {
        uploadAttachment(fileList) {
            let attachment = [];
            if (fileList.length) {
                attachment = fileList[0];
            } else {
                attachment = null;
            }

            if (attachment) {
                let api_data = {
                    attachment
                };

                if (this._newPost.attachments.length) {
                    this.removeAttachment(this._newPost.attachments[0].id);
                }

                this.$helpers.makeRequest('POST', '/post/upload', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        let response = api_response.data.response;

                        this._newPost.attachments.push(response);
                    }
                });
            }
        },
        removeAttachment(id) {
            let index = this.$helpers.getIndex(this._newPost.attachments, id);
            let attachment = this._newPost.attachments[index];

            let api_data = {
                image: attachment.name.split('/').reverse()[0]
            };

            this.$helpers.makeRequest('DELETE', '/post/remove-attachment/', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this._newPost.attachments.splice(index, 1);
                }
            });
        },
        // parseEmoji(str) {
        //     str = EmojiService.getEmojiImgFromUnicode(str);
        //     str = str.replaceAll(/alt=".*?"/igm, '');
        //     return str;
        // },
        parseNewPostContent(e) {
            // this._newPost.content = this.parseEmoji(e.target.innerHTML);
            // setTimeout(() => {
            // 	this.setCaretToEnd(this.$refs['textarea-post']);
            // }, 1);
        },
        setCaretToEnd(target /*: HTMLDivElement*/ ) {
            const range = document.createRange();
            const sel = window.getSelection();
            // range.selectNodeContents(target);
            range.collapse(false);
            sel.removeAllRanges();
            sel.addRange(range);
            // target.focus();
            range.detach(); // optimization
            // set scroll to the end if multiline
            target.scrollTop = target.scrollHeight;
        },
        scrollTo(container) {
            this.$scrollTo(this.$refs['textarea-post'], 500, {
                container,
                offset: (container == 'body') ? -130 : -20
            });
        },
        sendPost() {
            if (!this._newPost.content.trim()) {
                return;
            }

            if (this._newPost.id) {
                return this.editPost();
            }

            let api_data = {
                content: this._newPost.content,
                type: this._newPost.type,
                parent_id: this._newPost.parent_id,
                group_id: this._newPost.group_id,
                attachments: JSON.stringify(this._newPost.attachments),
            };

            if (this.type == 'comment') {
                api_data.post_id = this.$parent.post.id;
            }

            this.$helpers.makeRequest('POST', '/post/store', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.$toast.success({
                        title: 'پست ها',
                        message: `${this.type == 'post' ? 'پست' : 'نظر'} جدید شما با موفقیت ثبت شد`
                    });

                    let post = api_response.data.response.post;

                    if (this.type == 'post') {
                        if (this._newPost.parent_id) {
                            post.parent = this._newPost.parent;
                            this.$parent.closeModal();
                        }

                        if (this.outSideFlow == 'reshare-post') {
                            this.$root.$emit('reshare-post-then-update-twit', post);
                        } else if (this.outSideFlow != "reshare-post-from-other-profile") {
                            this.$parent.posts.data.unshift(post);
                        }

                    } else {
                        if (this._newPost.parent.type == 'comment') {
                            this._newPost.parent.comments.unshift(post);
                        } else {
                            // if(this.$parent.posts.data) {
                            // 	this.$parent.posts.data.unshift(post);
                            // }
                            // // this.$parent.comments.unshift(post);
                            this.$parent.comments.unshift(post);
                        }

                        this.$parent.post.comments_count += 1;
                    }

                    this.resetNewPost();
                }
            });
        },
        editPost() {
            let index = this.$helpers.getIndex(this.$parent.posts.data, this._newPost.id);
            let post = this.$parent.posts.data[index];

            let api_data = {
                content: this._newPost.content,
                attachments: JSON.stringify(this._newPost.attachments),
            };

            this.$helpers.makeRequest('POST', '/post/edit/' + post.id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                post.content = this._newPost.content;
                post.attachments = this._newPost.attachments;

                if (api_response.status == 200) {
                    this.$toast.success({
                        title: 'پست ها',
                        message: 'پست شما با موفقیت ویرایش شد'
                    });

                    if (post.attachments.length) {
                        post.content += `<br><img class="post-img" src="${this.$helpers.api_url() + post.attachments[0].name}">`;
                    }

                    this.resetNewPost();
                }
            });
        },
        resetNewPost() {
            this._newPost.id = null;
            this._newPost.attachments = [];
            this._newPost.comments = [];
            this._newPost.content = '';
            this._newPost.created_at = null;
            this._newPost.liked = false;
            this._newPost.likes = 0;
            this._newPost.parent_id = 0;
            this._newPost.reshares = 0;
            this._newPost.saved = false;
            this._newPost.user = null;
            this._newPost.user_id = null;
        },
        mentionOnOpen(key) {
            if (key === '@') {
                this.mentionItems = this.users;
            }
        },
    },
}
</script>
