<template>
<div>
    <div class="tgju-widget-title p-0 m-0 border-0">
        <div class="user-block-info">
            <div class="user-block-avatar hover-popper">
                <img v-if="post.user && post.user !== 0" :src="post.user.avatar">
                <img v-else :src="'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
            </div>
            <div class="user-block-data hover-popper">
                <router-link v-if="post.user && post.user !== 0 && !post.name" :to="{ name: 'ProfileOthers', params: { username: post.user.username }} " class="title">
                    {{ post.user.firstname }} {{ post.user.lastname }} <span class="user-block-title-tag">@{{ post.user.username }}</span>
                </router-link>
                <a v-else src="#" target="_blank" class="title">
                    {{ post.name }} <span class="user-block-title-tag">@{{ post.name }}</span>
                </a>
                <span class="time private">{{ toRelative(post.created_at) }}</span>
            </div>
            <div class="user-block-actions" v-if="user">
                <dropdown-menu v-if="$parent.$options.name == 'Post' && post.user.id == user.id" v-model="post.showMenu">
                    <a href="#" class="post-btn share dropdown-toggle" @click.prevent="">
                        <i class="uil uil-ellipsis-v" aria-hidden="true"></i>
                    </a>
                    <div slot="dropdown">
                        <!-- <a class="dropdown-item" href="#" @click.prevent="savePost(post.id)"><i class="fa fa-bookmark" aria-hidden="true"></i> ذخیره</a> -->
                        <a class="dropdown-item" href="#" @click.prevent="goEditPost(post.id)"><i class="uil uil-pen" aria-hidden="true"></i> ویرایش</a>
                        <a class="dropdown-item" href="#" @click.prevent="removePost(post.id)"><i class="fa fa-trash" aria-hidden="true"></i> حذف</a>
                    </div>
                </dropdown-menu>
            </div>
        </div>
    </div>
    <div class="tgju-widget-content profile-post-content" v-html="post.content"></div>
    <div class="user-block-reshare" v-if="post.parent && post.type == 'post'">
        <PostItem :post="post.parent" :group_id="post.group_id"></PostItem>
    </div>
    <div class="user-block-reshare" v-if="post.parent && post.type == 'tgju-comment'">
        <div class="reshare-label">
            <i class="uil uil-comment-alt-dots" aria-hidden="true"></i>
            <div class="text"> دیدگاه در سایت</div>
        </div>
        <PostItem :active_comment_show="false" :post="post.parent" :group_id="post.group_id"></PostItem>
    </div>
    <div class="post-btn-row">
        <div class="post-btn-item">
            <i class="uil uil-thumbs-up post-btn-icon pointer" aria-hidden="true" v-if="post.liked" @click="likePost(post.id)"></i>
            <i class="uil uil-thumbs-up post-btn-icon pointer" aria-hidden="true" v-else @click="likePost(post.id)"></i>
            <span class="post-btn-count">{{ post.likes }}</span>
        </div>
        <div class="post-btn-item" v-if="$parent.$options.name == 'Post'">
            <i class="uil uil-comment-alt-lines post-btn-icon pointer" aria-hidden="true" @click="open_comments_modal(post.id)"></i>
            <span class="post-btn-count">{{ post.comments_count }}</span>
        </div>
        <div class="post-btn-item">
            <dropdown-menu v-model="post.showShareMenu">
                <a class="post-btn share post-btn-icon dropdown-toggle"><i class="uil uil-share-alt"></i></a>
                <div slot="dropdown">
                    <a class="dropdown-item" @click="postShare('facebook', post)"><i class="fa fa-facebook" aria-hidden="true"></i> اشتراک در فیسبوک </a>
                    <a class="dropdown-item" @click="postShare('twitter', post)"><i class="fa fa-twitter" aria-hidden="true"></i> اشتراک در تویتر </a>
                    <a class="dropdown-item" @click="postShare('telegram', post)"><i class="fa fa-paper-plane" aria-hidden="true"></i> اشتراک در تلگرام </a>
                </div>
            </dropdown-menu>
        </div>
    </div>
</div>
</template>

<script>
import moment from "moment-jalaali";
import DropdownMenu from '@innologica/vue-dropdown-menu';
import PostItem from '@/components/Posts/PostItem.vue'

export default {
    name: 'PostItem',
    components: {
        'dropdown-menu': DropdownMenu,
        PostItem
    },
    props: ['post', 'group_id', 'active_comment_show'],
    data: function () {
        return {
            user: this.$helpers.userSync(),
        }
    },
    computed: {
        posts: {
            get() {
                return this.$parent.posts.data;
            },
            set(value) {
                this.$parent.posts.data = value;
            }
        },
        newPost: {
            get() {
                return this.$parent.newPost;
            },
            set(value) {
                this.$parent.newPost = value;
            }
        }
    },
    watch: {
        post: {
            handler: function (newValue, oldValue) {
                if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                    this.showMenu = false;
                    this.showShareMenu = false;

                    if (this.post.user) {
                        if (this.$helpers.isJson(this.post.user.avatar)) {
                            this.post.user.avatar = this.$helpers.api_url() + this.$helpers.json_pars(this.post.user.avatar).name
                        } else {
                            this.post.user.avatar = 'https://static.tgju.org/views/default/images/tgju-user-avatar.png';
                        }
                    }

                    // this.post.content = this.post.content.replaceAll(/<br><img class="post-img".*?>/gmi, '');
                    // if (this.post.attachments.length) {
                    //     this.post.content += `<br><img class="post-img" src="${this.$helpers.api_url() + this.post.attachments[0].name}">`;
                    // }
                }
            },
            immediate: true
        }
    },
    methods: {
        toRelative(date) {
            return moment(date).fromNow();
        },
        removePost(id) {
            let index = this.$helpers.getIndex(this.posts, id);

            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    this.$helpers.makeRequest('DELETE', '/post/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);
                        if (api_response.status == 200) {
                            this.posts.splice(index, 1);
                        }
                    });
                }
            });
        },
        savePost(id) {
            let index = this.$helpers.getIndex(this.posts, id);
            let post = this.posts[index];

            let api_data = {
                action: post.saved ? 'unsave' : 'save'
            };

            this.$helpers.makeRequest('GET', '/post/save/' + id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                if (api_response.status == 200) {
                    post.saved = !post.saved;
                }
            });
        },
        likePost(id) {
            let index = this.$helpers.getIndex(this.posts, id);
            let post = this.posts[index];

            let api_data = {
                action: post.liked ? 'unlike' : 'like'
            };

            this.$helpers.makeRequest('GET', '/post/like/' + id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                if (api_response.status == 200) {
                    post.liked = !post.liked;
                    post.likes = api_response.data.response.likes;
                }
            });
        },
        goEditPost(id) {
            let index = this.$helpers.getIndex(this.posts, id);
            let post = this.posts[index];

            this.newPost = this.$helpers.unbindObject(post);
            this.newPost.content = this.newPost.content.replaceAll(/<br><img class="post-img".*?>/gmi, '');

            this.$parent.setCaretToEnd();
            this.$parent.scrollTo('body');
        },
        postShare(type, post) {
            this.$helpers.socialShare(type, `پستی از داشبورد TGJU`, process.env.VUE_APP_URL + '/profile/2');
        },
        open_comments_modal(id) {
            let index = this.$helpers.getIndex(this.posts, id);
            let post = this.posts[index];

            let callbackEvent = this._uid;

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'PostComments.vue',
                config: {
                    title: 'نظر ها',
                    smallModal: false,
                    data: {
                        posts: this.posts,
                        post,
                        comments: post.comments,
                        group_id: this.group_id
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$helpers.setEvent(callbackEvent).then((status) => {
                if (!status) return;

                this.$root.$on(callbackEvent, (response) => {
                    if (response.action == 'loadComments') {
                        post.comments = response.data.comments;
                    }
                });
            });
        },
        open_reshare_modal(id) {
            let index = this.$helpers.getIndex(this.posts, id);
            let post = this.posts[index];

            let callbackEvent = this._uid;

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'PostReshare.vue',
                config: {
                    title: 'بازنشر',
                    smallModal: false,
                    data: {
                        posts: this.posts,
                        post,
                        group_id: this.group_id,
                        where: this.$route.params.username ? 'reshare-post-from-other-profile' : 'reshare-post'
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$helpers.setEvent(callbackEvent).then((status) => {
                if (!status) return;

                this.$root.$on(callbackEvent, (response) => {

                });
            });
        },
    },
}
</script>
